import React, { useState, useEffect, useRef } from 'react';
import './FirmSelection.css';
import axios from 'axios';

const FirmSelection = ({ loginDto, firmeList, onFirmSelected, onClose, setParentError}) => {
  const [selectedFirm, setSelectedFirm] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef(null);


  useEffect(() => {
    // Focus the search input when the component is mounted
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleFirmSelection = async (firma) => {
    setSelectedFirm(firma);
    onFirmSelected(firma);

    // Prepare the login DTO with the selected firm details
    const loginData = {
      ...loginDto, 
      IdFirme: firma.Id,
      IdBaze: firma.IdBaze,
    };

    
  const API_URL = process.env.NODE_ENV === 'production'  ? 'https://akord.rs/api' : 'http://localhost:15510/api';

    try {
        const response = await axios.post(`${API_URL}/Login/Korisnik/LoginUFirmu`, loginData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
       
        if (response.status === 200) {
            const data = response.data; 
            if (data) {
              window.history.replaceState(null, null, '/'); 
              window.location.replace(data); 
            }
        }
    } catch (err) {
        //
        if (err.response.status == 400) {
          // Axios includes the server response in `err.response`
          console.log('Error response:', err.response);
          setParentError(err.response.data.Message); 
        }
        else {
          setParentError("Došlo je do greške u komunikaciji sa serverom. Pritisnite ctrl i f5 na tastaturi u isto vreme i pokušajte ponovo. ");
          }
      } 

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && filteredFirms.length > 0) {
      handleFirmSelection(filteredFirms[0]);  // Select the first firm from the filtered list
    }
  };

    // Filter firms based on search term
  const filteredFirms = firmeList.filter(firma =>
        firma.NazivFirme.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <div className='modal-overlay'>
    <div className="modal-firmselection">
      <div className="modal-content-firmselection">
        <div className='modal-content-firmselection-header'>
        <h2>Odaberite firmu</h2>
        <button className="modal-close" onClick={onClose}>
            ×
          </button>
        </div>
        <input
          type="text"
          placeholder="Pretraži firmu..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          className="search-input"
          ref={searchInputRef}
        />
        <div className="firm-list">
          {filteredFirms.length > 0 ? (
            filteredFirms.map((firma) => (
              <div
                key={firma.NazivFirme}
                className={`firm-item ${selectedFirm?.NazivFirme === firma.NazivFirme ? 'selected' : ''}`}
                onClick={() => handleFirmSelection(firma)}
              >
                {firma.NazivFirme}
              </div>
            ))
          ) : (
            <div>Nemate odabranu firmu </div>
          )}
        </div> 
      </div>
    </div>
    </div>
  );
};

export default FirmSelection;
